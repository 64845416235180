<template>
    <div class="border-1">
        <div class="h3 mb-3">Как назначить ответственного?</div>
        <div v-if="expanded" class="d-inline-block mt-2">
            <p>Для назначения ответственного за заполнение отчетности выберите его из списка и нажмите на кнопку «Назначить».</p>
            <p>После этого ответственный может начать его заполнять, зайдя в систему под своим аккаунтом.</p>
            <p>Отслеживать состояние заполнения можно, просматривая их статус в системе на этой странице.</p>
            <img :src="images[0]" class="d-flex w-50 mb-2" />
            <p>После того как отчет сдан ответственным, Вы можете просмотреть его, для этого надо выбрать отчёт/отчёты со статусом «Готово» и нажать на кнопку «Просмотреть» наверху или внизу экрана.</p>
            <p>В открывшейся форме Вы можете просмотреть сводный отчет и в случае необходимости отредактировать его, переключив переключатель наверху формы.</p>
            <img :src="images[1]" class="d-flex w-50 mb-2" />
            <p>Также, если необходимо доработать отчет состороны ответственного, Вы можете отправить его на доработку, нажав на «Вернуть на доработку».</p>
            <p>Обратите внимание : Администратора ВПР, тоже можно назначить ответственным за заполнение отчета по классу. Он может его редактировать, нажав на кнопку «Заполнить», отображающуюся после назначения в этом же интерфейсе.</p>
        </div>
        <a class="color-primary fs-14 d-flex align-center" @click.prevent.stop="$emit('update:expanded', !expanded)">
            {{ expanded ? 'Свернуть' : 'Развернуть'}}
            <icon :name="expanded ? 'triangle-up' : 'triangle-down'" :color="$const.color.primary" />
        </a>
    </div>
</template>

<script>
import image1 from '@/assets/img/fisoko-report-info/1.png'
import image2 from '@/assets/img/fisoko-report-info/2.png'
import Icon from '@/components/icons/Index.vue'

export default {
    components: { Icon },
    props: {
        expanded: { type: Boolean, default: false }
    },
    computed: {
        images () {
            return [ image1, image2 ]
        }
    }
}
</script>