var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-detail-table--component"},[_c('div',{ref:"report-detail-table-scroller",staticClass:"report-detail-table-scroller pos-rel",on:{"scroll":_vm.onTableScroll}},[_c('div',{staticClass:"table"},[_vm._l((_vm.headers.length),function(coli){return _c('div',{key:coli-1,staticClass:"table-col",style:({
                    zIndex: [0, 1].includes(coli - 1) ? 1 : 0,
                    transform: [0, 1].includes(coli-1) ? ("translateX(" + _vm.scrollLeft + "px)") : 'none'
                })},[_c('div',{staticClass:"table-cell header-cell pos-rel px-2 py-2",class:{
                        'sibling-focused': _vm.focusedCell[1] === coli-1
                    },style:({
                        zIndex: 1,
                        width: ((_vm.getCellWidth(coli-1)) + "px"),
                        transform: ("translateY(" + _vm.scrollTop + "px)"),
                        'will-change': 'transform'
                    })},[_c('cell-header',{staticClass:"w-100",attrs:{"value":_vm.headers[coli-1].title,"hint":_vm.headers[coli-1].hint,"align":_vm.headers[coli-1].align}})],1),_vm._l((_vm.items.length),function(rowi){return _c('div',{key:rowi-1,staticClass:"table-cell pos-rel",class:{
                        'has-error': _vm.errors[rowi-1] && _vm.errors[rowi-1][coli-1],
                        'focused': _vm.checkIsFocused(rowi-1, coli-1),
                        'sibling-focused': _vm.focusedCell[0] === rowi-1 && _vm.focusedCell[0] !== rowi && _vm.focusedCell[1] !== coli-1,
                        'first-cell': coli - 1 === 0
                    },style:({
                        backgroundImage: !_vm.checkIsFocused(rowi-1, coli-1) && _vm.items[rowi-1][_vm.headers[coli-1].value].value === _vm.stringValues.skipped ? ("url(" + _vm.pattern1 + ")") : '',
                        height: (_vm.cellHeight + "px"),
                        width: ((_vm.getCellWidth(coli-1)) + "px")
                    }),on:{"click":function($event){return _vm.focusHandler(rowi-1, coli-1)}}},[_c('cell-input',{ref:("input_" + (rowi-1) + "_" + (coli-1)),refInFor:true,staticClass:"ma-2",class:[
                            _vm.items[rowi-1][_vm.headers[coli-1].value].value === _vm.stringValues.notPassed && 'color-orange',
                            _vm.items[rowi-1][_vm.headers[coli-1].value].value === _vm.stringValues.skipped && 'color-red'
                        ],attrs:{"model-value":_vm.items[rowi-1][_vm.headers[coli-1].value].value,"uid":("input_" + (rowi-1) + "_" + (coli-1)),"hint":_vm.items[rowi-1][_vm.headers[coli-1].value].hint,"tabindex":_vm.calcTabIndex(rowi-1, coli-1),"readonly":_vm.disabled || (!_vm.model[coli-1].selectMode && _vm.items[rowi-1][_vm.headers[coli-1].value].type !== 'input'),"placeholder":_vm.items[rowi-1][_vm.headers[coli-1].value].placeholder,"align":_vm.items[rowi-1][_vm.headers[coli-1].value].align,"select-mode":_vm.model[coli-1].selectMode,"items":_vm.model[coli-1].list},on:{"focused":function($event){return _vm.focusCell(rowi-1, coli-1)},"update:model-value":function ($event) { return _vm.onCellInputUpdate($event, rowi-1, coli-1); }}})],1)})],2)}),(_vm.contextMenu.show)?_c('v-list',{ref:"contextMenu",staticClass:"pos-abs elevation-5 rounded d-flex pa-0",staticStyle:{"width":"max-content!important","z-index":"1"},style:({ left: ((_vm.contextMenu.left) + "px"), top: ((_vm.contextMenu.top) + "px")})},_vm._l((_vm.availableInputValuesForFocusedCell),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":index},on:{"click":function($event){return _vm.setValueFromContextMenu(item)}}},[_c('v-list-item-title',{class:[
                                item === _vm.stringValues.notPassed && 'color-orange',
                                item === _vm.stringValues.skipped && 'color-red'
                            ]},[_vm._v(_vm._s(item))])],1)}),1):_vm._e()],2)]),_c('tool-bar',{staticClass:"mt-6",attrs:{"errors":_vm.errors,"disable-buttons":_vm.disabled || _vm.processing},on:{"signal:scroll-to-cell":function (ref) {
                                            var rowi = ref.rowi;
                                            var coli = ref.coli;
                                            var message = ref.message;

                                            return _vm.onScrollToCellHandler(rowi, coli, message);
},"signal:validate-inputs":_vm.onValidationButtonClicked,"signal:save":_vm.onSaveClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }