<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack, fetchModel }"
        update
        url-update="/fioko-data-collection/update/:id"
        url-back="/fioko-data-collection"
        action-one="fioko_data_collection/one"
        :fields="fields"
    >   
        <template name="title">
            <div class="h1 mt-n8 mb-4">{{ `Форма сбора результатов ФИС ОКО ${model ? model.name : ''}` }}</div>
        </template>
        <fioko-data-collection-form :model="model" @success="onSuccess" @back="onBack" @update:report="fetchModel" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import FiokoDataCollectionForm from '@/components/forms/FiokoDataCollectionForm'

export default {
    name: 'FiokoDataCollectionUpdate',
    components: { FormView, FiokoDataCollectionForm },
    computed: {
        fields () {
            return [ '*' ]
        }
    }
}
</script>