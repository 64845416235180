<template>
    <div class="d-flex align-start border-0" style="background: white;">
    
        <div class="d-flex w-4">
            <v-checkbox
                v-if="isCanEditFiokoDataCollectionSection"
                v-model="localSelected"
                class="mt-0"
                @change="$event => $emit('update:select', { value: !!$event, reportId: localModel.id })"
            ></v-checkbox>
        </div>

        <span class="d-flex flex-column w-96">
            <div class="d-flex align-center mb-6">
                <span class="fs-18 fw-500 mr-3">Отчет</span>
                <v-chip :color="reportStatuses[localModel.status].color" class="mr-3">
                    <span :style="{ color: reportStatuses[localModel.status].fontColor }">Статус: {{ reportStatuses[localModel.status].text }}</span>
                </v-chip>
                <v-chip
                    v-if="localModel.status === 'done' && isCanEditFiokoDataCollectionSection"
                    @click="returnToRevision(localModel)"
                >
                    <v-icon
                        :color="reportStatuses[null].fontColor"
                        class="mr-2"
                        style="transform: scale(-1, 1) rotate(-45deg);"
                    >mdi-reload</v-icon>
                    <span :style="{ color: reportStatuses[null].fontColor }">Вернуть на доработку</span>
                </v-chip>
            </div>
            

            <div class="d-flex align-center border-1 pa-0">
                <span v-if="groups" class="d-flex align-center w-30 pa-3" :style="`border-right: 1px solid ${$const.color.extraLightGrey}`">
                    <selector
                        v-model="localModel.group_id"
                        :items="groups"
                        label="Данные для класса"
                        :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled || localModel.status !== 'waiting'"
                        class="w-100"
                        style="height: 40px;"
                    />
                </span>
                <span v-if="teachers && subjects" class="d-flex align-center w-70 pa-3">
                    <selector-with-filter
                        v-model="localModel.teacher_id"
                        filter-label="Фильтр по предметам"
                        filter-key="subjects"
                        :filter="subjects"
                        :filter-function="customFilterFunction"
                        items-label="Ответственный"
                        :items="responsibleUsers"
                        :default-filter="defaultSubject"
                        class="w-100"
                        style="height: 40px;"
                        :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled"
                    >
                        <div class="d-flex w-100" style="height: 40px">
                            <div class="d-flex flex-column justify-center text-left w-100" style="text-transform: none; letter-spacing: normal;">
                                <span class="fw-400 fs-12" :class="{'mb-2': responsible}" :style="{ color: $const.color.fontGrey }">Предмет</span>
                                <span v-if="responsible">{{ responsible.text }}</span>
                            </div>
                            <v-icon :color="$const.color.fontGrey">mdi-menu-down</v-icon>
                        </div>
                    </selector-with-filter>
                </span>
            </div>

            
            <div class="d-flex align-center w-100 mt-9">
                <span v-if="userUpdateBy" class="color-font-grey fw-400 fs-12">Последнее изменение от: {{ userUpdateBy.name }}</span>

                <v-spacer />
                <template v-if="isCanEditFiokoDataCollectionSection">
                    <v-btn
                        v-if="(currentUser.id === report.teacher_id) && (currentUser.id === localModel.teacher_id)"
                        outlined
                        height="40px"
                        class="button-stylized-light-blue mr-2"
                        :loading="editButtonLoading"
                        @click="$emit('click:edit', localModel)"
                    >
                        <v-icon size="22px" class="mr-2">mdi-pencil</v-icon>
                        Заполнить
                    </v-btn>
                    <v-btn
                        outlined
                        height="40px"
                        class="button-stylized-light-blue mr-2"
                        :disabled="(!localModel.teacher_id || !localModel.group_id) || totalDisabled"
                        @click="updateReport"
                    >
                        <icon
                            name="send"
                            :color="(!localModel.teacher_id || !localModel.group_id) || totalDisabled ? $const.color.grey : $const.color.primary"
                            class="mr-2"
                        />
                        Назначить
                    </v-btn>

                    <v-btn
                        v-if="[null, 'waiting'].includes(localModel.status)"
                        outlined
                        height="40px"
                        class="button-stylized-light-red"
                        :disabled="totalDisabled"
                        @click="dialogs.reportToRemove = localModel.id"
                    >
                        <v-icon class="mr-2">mdi-trash-can</v-icon>
                        Удалить
                    </v-btn>

                    <confirm-action-with-pass-dialog
                        :value="!!dialogs.reportToRemove"
                        title="Для подтверждения удаления введите пароль от текущей учетной записи"
                        :action="() => removeReport(dialogs.reportToRemove)"
                        @change="dialogs.reportToRemove = null"
                    />
                </template>
            </div>
        </span>

    </div>
</template>

<script>
import { reportStatuses } from './dictionaries'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import ConfirmActionWithPassDialog from '@/components/template/ConfirmActionWithPassDialog.vue'
import SelectorWithFilter from '@/components/template/SelectorWithFilter.vue'
import Selector from '@/components/template/Selector.vue'
import Icon from '@/components/icons/Index.vue'

export default {
    mixins: [isUserCanMixin],
    components: { ConfirmActionWithPassDialog, SelectorWithFilter, Selector, Icon },
    props: {
        report: { type: Object, required: true },
        selected: { type: Boolean, default: false },
        teachers: { type: Array, default: null },
        groups: { type: Array, default: null },
        disabled: { type: Boolean, default: false },
        editButtonLoading: { type: Boolean, default: false },
        defaultSubject: { type: String, default: null }
    },
    data () {
        return {
            localSelected: false,
            localDisabled: false,
            dialogs: {
                reportToRemove: null
            },
            userUpdateBy: null,
            localModel: null
        }
    },
    computed: {
        reportStatuses () { return reportStatuses },
        subjects () { return this.$store.getters['calendar_event/subjects'] },
        totalDisabled () { return this.disabled || this.localDisabled },
        currentUser () {
            return this.$store.state.user.profile
        },
        responsible () {
            return this.teachers.find(t => t.value === this.localModel.teacher_id)
        },
        responsibleUsers () {
            const rolesToNumber = {
                'admin_vpr': 1,
                'teacher': 0
            }
            return [...this.teachers]
                .sort((a, b) => rolesToNumber[a.role] - rolesToNumber[b.role])
                .map(item => ({
                    ...item,
                    subtext: item.role === 'admin_vpr' ? 'Администратор ВПР' : null
                }))
        },
        selectedGroup () {
            return this.groups.find((g) => g.value === this.localModel.group_id)
        }
    },
    watch: {
        selected: {
            immediate: true,
            handler () {
                this.localSelected = this.selected
            }
        }
    },
    created () {
        this.localModel = _.cloneDeep(this.report)
        this.fetchUserUpdatedBy()
    },
    methods: {
        customFilterFunction (item, filterValue) {
            const subjects = item.subjects
            return subjects.includes(filterValue)
        },
        async fetchUserUpdatedBy () {
            if (!this.localModel.updated_by)
                return

            try {
                const { success, error, data } = await this.$store.dispatch('user/one', {
                    id: this.localModel.updated_by,
                    fields: 'name'
                })

                if (!success)
                    throw new Error(error)

                this.userUpdateBy = data
            } catch (e) {
                console.error(e)
            }
        },
        async returnToRevision (report) {
            report.status = 'processing'
            this.updateReport()
        },
        async updateReport () {
            try {
                this.localDisabled = true
                await this.$store.dispatch('fioko_report/update', this.localModel)
                this.$emit('updated', {...this.localModel})
            } catch (e) {
                console.error(e)
            }
            finally {
                this.localDisabled = false
            }
        },
        async removeReport (reportId) {
            try {
                this.localDisabled = true
                await this.$store.dispatch('fioko_report/delete', { id: reportId })
                this.$emit('removed', reportId)
            } catch (e) {
                throw new Error(e)
            } finally {
                this.localDisabled = false
            }
        },
    }
}
</script>